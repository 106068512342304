import React, {createRef} from "react";
import {QuickAction} from "./QuickAction";
import "./QuickActionsGroup.scss";
import SimpleBar from 'simplebar-react';
import {add, reset} from "./bacoins";

let shuffle = (arr) => arr.map(value => ({value, sort: Math.random()}))
    .sort((a, b) => a.sort - b.sort)
    .map(({value}) => value)

export class QuickActionsGroup extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.ref = createRef();
        this.scroller = createRef();
        this.bad = ['🍬', '🍬', '🍬', '🍬', '🍫', '🍫', '🍫', '🍫', '🍭'];
        this.good = ['🥓', '🥓', '🍳', '🥩', "🍖", '🥑', '🥦'];
        this.bads = [...this.bad, ...this.bad, ...this.bad];
        this.goods = [...this.good, ...this.good];
        this.all = [...this.bad, ...this.good];
        this.alls = Array(40).fill(this.all).flat(1);
        this.state = {
            speed: this.speed(),
            alls: shuffle(this.alls),
            leftCurtain: false,
            rightCurtain: false,
        };
    }

    speed() {
        return Math.random() * 40 + 110;
    }


    componentDidMount = () => {
        this.scroller.current.addEventListener('scroll', this.curtains);
        this.ref.current.addEventListener('DOMSubtreeModified', this.curtains);
        this.curtains();
    }

    curtains = () => {
        this.setState((s) => ({
            ...s,
            leftCurtain: this.scroller.current.scrollLeft !== 0,
            rightCurtain: this.scroller.current.scrollLeft < this.ref.current.offsetWidth - this.scroller.current.offsetWidth - 1,
        }));
    }

    render() {
        return <div className="quick-actions-group-wrapper">
            <div
                className={`quick-actions-group-wrapper-curtain-left ${this.state.leftCurtain ? 'visible' : ''}`}></div>
            <div
                className={`quick-actions-group-wrapper-curtain-right ${this.state.rightCurtain ? 'visible' : ''}`}></div>
            <SimpleBar className="quick-actions-group" autoHide={false} scrollableNodeProps={{ref: this.scroller}}>
                <div
                    style={{'--animation-speed': `${this.state.speed}s`}}
                    className={`quick-actions-group-actions ${this.props.lock ? 'quick-actions-group-actions-scroll-animation' : ''}`}
                    ref={this.ref}>
                    {this.props.lock ? this.state.alls.map(q => <button
                            className="quick-action game"
                            onClick={() => this.clicked(q)}>
                            <span>{q}</span>
                        </button>) :
                        this.props.actions.map(q => <QuickAction action={q} act={this.props.act}
                                                                 lock={this.props.lock}/>)}
                </div>
            </SimpleBar>
        </div>;
    }

    clicked(q) {
        if (this.goods.includes(q)) {
            add();
        } else {
            reset();
        }
    }
}
