import React from "react";
import "./QuickAction.scss";

export class QuickAction extends React.Component {
    render() {
        return <button
            className="quick-action"
            disabled={this.props.lock}
            onClick={() => this.props.act(this.props.action)}>
            <span>{this.props.action.emoji} {this.props.action.label}</span>
        </button>;
    }

}
