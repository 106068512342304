import React, {createRef} from "react";
import "./ChatMessage.scss";
import Typed from "typed.js";
import Choice from "./Chat/Choice";
import ReactDOM from "react-dom/client";
import ChatMessageReactionPopover from "./ChatMessageReactionPopover";


export class KetoCoachChatMessage extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.message = this.props.value;
        this.statements = this.props.statements ?? {};
        this.new = this.props.new;
        this.textPlace = [];
        this.messageRef = createRef();
    }

    componentDidMount = async () => {
        const text = await this.parseAssistantResponse(this.message);

        for (let i = 0; i < text.length; i++) {
            await this.block(this.textPlace[i], text[i]);
        }
        if(!this.messageRef.current) {
            return;
        }
        [...this.messageRef.current.querySelectorAll('a')].map(e => ReactDOM.createRoot(e)
            .render(<ChatMessageReactionPopover statement={this.props.statements?.[e.dataset.href] ?? 'ERROR'}
                                                text={e.innerHTML}/>)
        );
    }

    addRef = (ref) => {
        this.textPlace.push(ref);
    }

    render() {
        return <>
            <div className="chat-message-profile-avatar">
                <div>
                    <img src='/ketocoach-chat-avatar.png' alt=""/>
                </div>
            </div>
            <div className="chat-message-profile-name">
                <div>
                    <h3>KetoCoach:</h3>
                </div>
            </div>
            <div className="chat-message-content" ref={this.messageRef}>
                <div ref={this.addRef}></div>
                <div ref={this.addRef}></div>
                <div ref={this.addRef}></div>
                <div ref={this.addRef}></div>
                <div ref={this.addRef}></div>
                <div ref={this.addRef}></div>
                <div ref={this.addRef}></div>
                <div ref={this.addRef}></div>
                <div ref={this.addRef}></div>
                <div ref={this.addRef}></div>
                <div ref={this.addRef}></div>
                <div ref={this.addRef}></div>
            </div>
        </>;
    }

    block = async (ref, block) => {
        if (typeof block === "string") {
            let text = `${block.replace(/\*\*(.*?)\*\*/g, '<span class="bold">$1</span>')}`;
            text = `${text.replace(/__(.*?)__/g, '<span class="italic">$1</span>')}`;
            text = `${text.replace(/\[(.+?)]\(#(.+?)\)/g, '<a data-href="$2">$1</a>')}`;
            text = this.convertToHtmlList(this.escapeHtml(text.trim()));
            if (this.new) {
                return await new Promise(res => {
                    new Typed(ref, {
                        strings: [this.escapeHtml(text)],
                        typeSpeed: 5,
                        cursorChar: '',
                        onComplete: res
                    });
                })
            } else {
                ref.innerHTML = text;
            }
        }
        if (typeof block !== "object") {
            return;
        }
        if (block.type === "choice") {
            ReactDOM.createRoot(ref).render(<Choice choice={block} choose={this.props.choose}></Choice>);
        }
    }


    convertToHtmlList(text) {
        const lines = text.split('\n');
        const regexPattern = /^[*|-] \S+ .+$/;

        const chunks = [];
        let number = 0;
        // Check if all lines follow the pattern
        for (let line of lines) {
            if (!regexPattern.test(line)) {
                chunks[number] = line;
                number++;
            } else {
                chunks[number] = [...(chunks[number] ?? []), line];
            }
        }

        return chunks.map((s) => {
            if (typeof s === "string") {
                return s;
            } else {
                const htmlList = s.map(line =>
                    `<li>${line.substring(2)}</li>` // Remove '* ' and wrap in <li></li>
                ).join('');
                return `<ul>${htmlList}</ul>`;
            }
        }).join('\n');

    }

    escapeHtml(unsafe) {
        return unsafe.replace(/&/g, "and");
    }

    async parseAssistantResponse(response) {
        return response.trim().split('\n\n')
            .map(i => this.convertToHtmlList(i.trim()))
            .map(i => `<p>${i}</p>`);
    }
}
