import {getUserToken} from "./Auth";

export default class Backend {
    static async createChat() {
        const res = await backendFetch(process.env.REACT_APP_BACKEND + '/create', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${await getUserToken()}`
            },
        });
        if (!res.ok) {
            throw new Error(`${res.status} ${res.statusText}`);
        }
        return (await res.json()).chatId;
    }
    
    static async click(topic: string) {
        const res = await backendFetch(process.env.REACT_APP_BACKEND + `/click`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${await getUserToken()}`
            },
            body: JSON.stringify({topic})
        });

        if (!res.ok) {
            throw new Error(`${res.status} ${res.statusText}`);
        }
    }

    static async sendMessage(chatId: string, message: Object) {
        const res = await backendFetch(process.env.REACT_APP_BACKEND + `/send/${chatId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${await getUserToken()}`
            },
            body: JSON.stringify(message)
        });
        if (!res.ok) {
            throw new Error(`${res.status} ${res.statusText}`);
        }
    }

    static async blockStatement(statement: string) {
        const res = await backendFetch(process.env.REACT_APP_BACKEND + '/block', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${await getUserToken()}`
            },
            body: JSON.stringify({
                statement
            })
        });
        if (!res.ok) {
            throw new Error(`${res.status} ${res.statusText}`);
        }
    }

}

async function backendFetch(path: string, params: { [key: string]: any }): Promise<Response> {
    const response = await fetch(path, params);
    if (response.headers.has('X-Trace-Id')) {
        console.log(`You can see ${path} response trace here: https://mermaid.live/edit/?code=${process.env.REACT_APP_BACKEND}/public/${response.headers.get('X-Trace-Id')}`);
    }
    return response;
}
