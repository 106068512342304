import {signInAnonymously as firebaseSignInAnonymously, signOut as firebaseSignOut} from "@firebase/auth";
import {auth} from "./firebase";
import {User as FirebaseUser} from "@firebase/auth/dist/auth-public";

export type User = FirebaseUser;

export const getUserOrCreateAnonymous = async (): Promise<User> => {
    return new Promise<User>(async res => {
        const unsubscribe = auth.onAuthStateChanged(async (user: User | null) => {
            if (user) {
                unsubscribe();
                res(user);
            } else {
                let tries = 0;
                while (tries < 3) {
                    if (await singInAnonymously()) {
                        break;
                    }
                    tries++;
                }
            }
        });
    })
}

export const singInAnonymously = async () => {
    console.log('Anonymous sing in');
    return firebaseSignInAnonymously(auth);
}
export const getUser = async (): Promise<User> => {
    return new Promise<User>(async res => {
        const unsubscribe = auth.onAuthStateChanged(async (user: User | null) => {
            if (user) {
                unsubscribe();
                res(user);
            }
        });
    });
}

export const getUserToken = async () => await ((await getUser()).getIdToken());

export const signOut = async () => {
    await firebaseSignOut(auth);
}
