import React, {createRef} from "react";
import "./Chat.scss";
import {Send} from "./Send";
import {QuickActionsGroup} from "./QuickActionsGroup";
import SimpleBar from "simplebar-react";
import Backend from "./Backend";
import ChatMessage from "./ChatMessage";
import {VirtualChatMessage} from "./ChatDB";

export class ChatComponent extends React.Component {

    messagesBottomAnchors = [];

    constructor(props, context) {
        super(props, context);
        this.chat = createRef();
        this.emptySuggestions = {
            popular: [],
            related: [],
        };
        this.state = {
            messages: [],
            suggestions: this.emptySuggestions,
            lock: false,
            scrolled: false,
            hideScrollbar: true,
        }
    }

    addMessagesBottomAnchorRef = (ref) => {
        this.messagesBottomAnchors.push(ref);
    };

    componentDidMount = async () => {
        const history = await this.subscribeToChatUpdates();
        await this.initializeHistory(history);
        this.chat.current.addEventListener('scroll', () => this.setState((s) => ({
            ...s,
            scrolled: this.chat.current.scrollTop !== 0,
        })));
        let prev = this.chat.current.scrollHeight;
        setTimeout(() => {
            if (this.messagesBottomAnchors[this.messagesBottomAnchors.length - 1]) {
                this.messagesBottomAnchors[this.messagesBottomAnchors.length - 1].scrollIntoView({
                    behavior: "smooth",
                    block: 'nearest'
                })
            }
        }, 200);
        setTimeout(() => this.setState({hideScrollbar: false}), 100);
        this.chatAnchorScroller = setInterval(() => requestAnimationFrame(() => {
            const componentGrow = prev < this.chat.current.scrollHeight;
            const anchorScroll = this.chat.current.scrollTop > this.chat.current.scrollHeight - this.chat.current.offsetHeight - 100;
            if (anchorScroll && componentGrow) {
                this.chat.current.scrollTop = this.chat.current.scrollHeight;
                prev = this.chat.current.scrollHeight;
            }
        }));
    }

    componentWillUnmount() {
        clearInterval(this.chatAnchorScroller);
    }

    async initializeHistory(history) {
        history.map(message => {
            if (message.value.type === 'assistant') {
                this.coachResponded(message);
            } else {
                this.userResponded(message);
            }
            return null;
        })
    }

    subscribeToChatUpdates = async () => {
        return await this.props.chat.chatMessagesSubscription((message) => {
            if (message.value.type === 'assistant') {
                this.coachResponded(message);
            } else {
                this.userResponded(message);
            }
        });
    };

    async coachResponded(message) {
        this.setState(prevState => ({
            ...prevState,
            messages: [...prevState.messages, message],
        }));
        message.onFinished(() => this.setState({lock: false}));
        message.onSuggestions((m) => this.setState({suggestions: m.suggestions}));
    }

    userResponded(message) {
        if (this.state.messages[this.state.messages.length - 1]?.value?.message === message.value.message) {
            return;
        }
        this.setState(prevState => ({
            ...prevState,
            messages: [...prevState.messages, message],
        }));
    }

    sendMessage = async (m) => {
        this.setState(prevState => ({
            ...prevState,
            lock: true,
            messages: [...prevState.messages, new VirtualChatMessage({
                type: "user",
                message: m.message
            })],
        }));
        await Backend.sendMessage(this.props.chat.id, m);
    };

    simpleMessage = async (m) => {
        await this.sendMessage({message: m});
    }

    choose = (option) => {
        this.sendMessage({"name": "choose", parameters: [option], 'label': option});
    }

    act = async (action) => {
        await this.simpleMessage(action.prompt);
    }

    render() {
        return <div className="chat">
            <div>
                <div className={"chat-block chat-messages"}>
                    <div>
                        <div>
                            <div className={`chat-messages-curtain ${this.state.scrolled ? 'visible' : ''}`}>
                            </div>
                            <SimpleBar
                                className={`chat-messages-content ${this.state.hideScrollbar ? 'scrollbar-hidden' : null}`}
                                forceVisible={true}
                                autoHide={false}
                                scrollableNodeProps={{ref: this.chat}}>
                                <div>
                                    {this.state.messages.map((message, index) => (
                                        <ChatMessage key={index} message={message}
                                                     bottomAnchorRef={this.addMessagesBottomAnchorRef}/>
                                    ))}
                                </div>
                            </SimpleBar>
                        </div>
                    </div>
                </div>
                <div className="chat-alert">

                </div>
                <div className="chat-block chat-wide-quick-actions">
                    <QuickActionsGroup actions={this.state.suggestions.popular} act={this.act}
                                       lock={this.state.lock}/>
                </div>
                <div className="chat-block chat-narrow-quick-actions">
                    <QuickActionsGroup actions={this.state.suggestions.related} act={this.act}
                                       lock={this.state.lock}/>
                </div>
                <div className="chat-block chat-send">
                    <Send send={this.simpleMessage} lock={this.state.lock}/>
                </div>
            </div>
        </div>;
    }

}
