import React from "react";
import "./Send.scss";


export class Send extends React.Component {
    placeholder = "Just Ask me what you need...";

    constructor(props, context) {
        super(props, context);
        this.sender = props.send;
        this.state = {
            input: ''
        };
    }

    send = (e) => {
        this.sender(this.state.input);
        this.setState({input: ''});
        e.preventDefault();
    }

    render() {
        return <div>
            <form onSubmit={this.send} className="send">
                <input
                    value={this.state.input}
                    onChange={(e) => this.setState(p => ({...p, input: e.target.value}))}
                    type="text"
                    placeholder={this.placeholder} disabled={false}/>
                <div className="send-button">
                    <div>
                        <button type={"submit"} disabled={this.props.lock || !this.state.input}>
                            <img src="/arrow_up.png" alt="send"/>
                        </button>
                    </div>
                </div>
            </form>
        </div>;
    }
}
