import {
    chatMessagesSubscription,
    ObservableChatMessage,
    VirtualChatMessage
} from "./ChatDB.ts";
import {getObjectFromRemoteConfig} from "./firebase.ts";

export interface Chat {
    chatMessagesSubscription(callback: (m: ObservableChatMessage) => void): Promise<ObservableChatMessage[]>;
    get id(): string;
}

export class VirtualOldChat implements Chat {

    async chatMessagesSubscription(callback: (m: ObservableChatMessage) => void): Promise<ObservableChatMessage[]> {
        return [new VirtualChatMessage(await getObjectFromRemoteConfig('firstMessage'))];
    }

    get id(): string {
        throw new Error("Virtual chat has no Id");
    }
}

function delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

function resolveAfterMinimumTime<T>(existingPromise: Promise<T>, minDurationMs: number): Promise<T> {
    return Promise.all([existingPromise, delay(minDurationMs)]).then(values => {
        return values[0];
    });
}

export class VirtualNewChat implements Chat {

    async chatMessagesSubscription(callback: (m: ObservableChatMessage) => void): Promise<ObservableChatMessage[]> {
        const m = new VirtualChatMessage({
            type: "assistant",
            suggestions: {
                popular: [],
                related: []
            }
        });

        setTimeout(async () => m.publish(await resolveAfterMinimumTime(getObjectFromRemoteConfig('firstMessage'), 100)));
        return [m];
    }

    get id(): string {
        throw new Error("Virtual chat has no Id");
    }
}

export class RealChat implements Chat {

    constructor(public readonly id: string) {
    }

    async chatMessagesSubscription(callback: (m: ObservableChatMessage) => void): Promise<ObservableChatMessage[]> {
        return await chatMessagesSubscription(this.id, callback);
    }

}

export class DeferredChatLoader implements Chat {
    private callbacks: ((m: ObservableChatMessage) => void)[] = [];
    private loadedChat?: Chat;

    constructor(private initial: Chat) {
    }

    get id(): string {
        return this.loadedChat?.id ?? this.initial?.id;
    }

    public loaded(chat: Chat): void {
        this.loadedChat = chat;
        this.callbacks.map(async c => (await chat.chatMessagesSubscription(c)).map(m => c(m)));
    }

    async chatMessagesSubscription(callback: (m: ObservableChatMessage) => void): Promise<ObservableChatMessage[]> {
        this.callbacks.push(callback);
        return this.initial.chatMessagesSubscription(callback);
    }
}
