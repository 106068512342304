import React from "react";
import UserChatMessage from "./UserChatMessage";
import {KetoCoachChatMessage} from "./KetoCoachChatMessage";
import {get, listen} from "./bacoins";

export default class ChatMessage extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {value: this.props.message.value, finished: this.props.message.finished(), bacoins: get()};
        this.old = this.props.message.finished();
        this.props.message.observe((val) => this.setState(() => {
            return {
                value: val,
                finished: !!val.message
            };
        }));
    }

    componentDidMount() {
        listen(b => this.setState({bacoins: b}));
    }

    render() {
        if (this.state.value.type === "user") {
            return <div className="chat-message">
                <UserChatMessage key={'user'} value={this.state.value}></UserChatMessage>
                <div className="chat-message-bottom-anchor" ref={this.props.bottomAnchorRef}>
                </div>
            </div>;
        }
        if (this.old) {
            return <div className="chat-message"><KetoCoachChatMessage
                key={'old'}
                new={false}
                statements={this.state.value.statements}
                value={this.state.value.message}>
            </KetoCoachChatMessage>
                <div className="chat-message-bottom-anchor" ref={this.props.bottomAnchorRef}>
                </div>
            </div>
        }
        if (!this.state.finished) {
            return <div className="chat-message"><KetoCoachChatMessage
                key={'loading'}
                new={true}
                value={`Thinking...`}>
            </KetoCoachChatMessage>
                <div className="chat-message-bottom-anchor" ref={this.props.bottomAnchorRef}>
                </div>
            </div>;
        } else {
            return <div className="chat-message"><KetoCoachChatMessage
                key={'new'}
                new={true}
                value={this.state.value.message}
                statements={this.state.value.statements}
            >
            </KetoCoachChatMessage>
                <div className="chat-message-bottom-anchor" ref={this.props.bottomAnchorRef}>
                </div>
            </div>;
        }
    }
}
