import React, {Component} from 'react';
import './App.scss';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Home from "./Home";
import Debug from "./Debug";
import {getUserOrCreateAnonymous, User} from "./Auth";

function isDebug() {
    const search = new URLSearchParams(window.location.search);
    return search.has('debug');
}

class App extends Component<{}, { user?: User | null }> {
    private unsubscribeAuth?: null;

    constructor(props) {
        super(props);
        this.state = {
            user: null
        };
    }

    componentDidMount = async () => {
        this.setState({user: await getUserOrCreateAnonymous()});
    };

    render() {
        if (!this.state.user) {
            return <>{isDebug() ? <Debug/> : null}</>;
        }
        return (
            <Router>
                <div className={"ketocoach-app"}>
                    {isDebug() ? <Debug/> : null}
                    <div className={"ketocoach-mobile"}>
                        <Routes>
                            <Route path="/" element={<Home user={this.state.user}/>}/>
                        </Routes>
                    </div>
                    <div className={"ketocoach-qr"}>
                        <div>
                            <img src="/qr.png" alt=""/>
                        </div>
                    </div>
                </div>
            </Router>
        );
    }
}

export default App;
