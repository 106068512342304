import {initializeApp} from "firebase/app";
import {fetchAndActivate, getRemoteConfig, getValue} from "firebase/remote-config";
import {getAuth} from "firebase/auth";
import {initializeFirestore, persistentLocalCache} from "firebase/firestore";


const firebaseConfig = {
    apiKey: "AIzaSyDw3kBY_rG5c-xMMUvqAA5IEL0Sy805eGU",
    authDomain: "ketocoach-869e8.firebaseapp.com",
    projectId: "ketocoach-869e8",
    storageBucket: "ketocoach-869e8.appspot.com",
    messagingSenderId: "565229730274",
    appId: "1:565229730274:web:286eda1d227ed1242ee1bb",
    measurementId: "G-9H78EB6C91"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const remoteConfig = getRemoteConfig(app);
remoteConfig.settings.minimumFetchIntervalMillis = 60000;
fetchAndActivate(remoteConfig).then(r => ({}));
export const getObjectFromRemoteConfig = async (val: string) => {
    await fetchAndActivate(remoteConfig);
    const variables = getValue(remoteConfig, val).asString();

    return JSON.parse(variables);
}
export const auth = getAuth(app);
export const firestore = initializeFirestore(app, {localCache: persistentLocalCache({})});
