import {doc, DocumentReference, getDoc} from "firebase/firestore";
import {firestore} from "./firebase";
import {collection, getDocs, onSnapshot, orderBy, query} from "@firebase/firestore";

export type ChatMessage = Record<string, any>;
export type ChatMessageObserver = (d: ChatMessage) => void;

export class VirtualChatMessage implements ObservableChatMessage {
    private observers: ChatMessageObserver[] = [];

    constructor(public value: ChatMessage) {
        this.observers = [(value) => this.value = value];
    }

    finished = () => !!this.value.message;
    observe = (c: ChatMessageObserver) => {
        this.observers.push(c);
    };
    onFinished = (realObserver: ChatMessageObserver) => {
        if(this.finished()){
            realObserver(this.value);
        }
        this.observers.push((m: ChatMessage) => {
            if (m.message) {
                realObserver(m);
            }
        });
    }
    onSuggestions = (realObserver: ChatMessageObserver) => {
        if(this.value.suggestions){
            realObserver(this.value);
        }
        this.observers.push((m: ChatMessage) => {
            if (m.suggestions) {
                realObserver(m);
            }
        });
    }

    public publish(value: ChatMessage) {
        this.observers.map(o => o(value));
    }
}

export interface ObservableChatMessage {
    get value(): ChatMessage;
    finished: () => boolean;
    onFinished: (c: ChatMessageObserver) => void;
    onSuggestions: (c: ChatMessageObserver) => void;
    observe: (c: ChatMessageObserver) => void;
}

class FirebaseRealtimeChatMessage implements ObservableChatMessage {
    private readonly vitalChatMessage: VirtualChatMessage;
    alreadyObserving: boolean = false;

    get value() {
        return this.vitalChatMessage.value;
    }

    constructor(initial: ChatMessage, public readonly ref: DocumentReference) {
        this.vitalChatMessage = new VirtualChatMessage(initial);
    }

    onFinished = (c: ChatMessageObserver) => this.vitalChatMessage.onFinished(c);
    onSuggestions = (c: ChatMessageObserver) => this.vitalChatMessage.onSuggestions(c);

    finished = () => this.vitalChatMessage.finished();
    observe = (c: ChatMessageObserver) => {
        this.vitalChatMessage.observe(c);
        this.firebaseObserve();
    };

    private firebaseObserve() {
        if (!this.finished() && !this.alreadyObserving) {
            onSnapshot(this.ref, snapshot => this.vitalChatMessage.publish(snapshot.data() as any));
            this.alreadyObserving = true;
        }
    }
}

export const getChat = async (id: string) => {
    try {
        const messages: any[] = [];
        (await getDocs(query(collection(firestore, `/chats/${id}/messages`), orderBy('created', 'asc')))).forEach(i => messages.push(i.data()));
        const chat = {...(await getDoc(doc(firestore, `/chats/${id}`))).data(), messages: messages};
        console.log(chat);
        return chat
    } catch (error) {
        console.error("Error fetching chat:", error);
        throw error;  // Re-throw the error to be handled by the caller
    }
}

export const chatMessagesSubscription = async (id: string, callback: (m: ObservableChatMessage) => void): Promise<ObservableChatMessage[]> => {
    return new Promise((res, rej) => {
        try {
            let first = true;
            onSnapshot(query(collection(firestore, `/chats/${id}/messages`), orderBy('created', 'asc')), snapshot => {
                if (first) {
                    const value: any[] = [];
                    snapshot.docChanges().forEach(change => value.push(new FirebaseRealtimeChatMessage(change.doc.data(), change.doc.ref)));
                    res(value)
                    first = false;
                    return;
                }
                snapshot.docChanges().forEach(change => {
                    if (change.type === "added") {
                        callback(new FirebaseRealtimeChatMessage(change.doc.data(), change.doc.ref));
                    }
                });
            });
        } catch (error) {
            console.error("Error subscribing to chat:", error);
            rej(error);
        }
    });

}
