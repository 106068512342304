import {Popover} from "react-tiny-popover";
import React from "react";
import "./Popover.scss";
import "./ChatMessageReactionPopover.scss";
import Backend from "./Backend";

export default class ChatMessageReactionPopover extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            open: false
        }
    }

    render() {
        return <Popover
            isOpen={this.state.open}
            positions={['top']} // preferred positions by priority
            content={<div className={"popover chat-message-reaction-popover"}
                          onClick={() => this.setState({open: false})}>
                <button onClick={() => Backend.blockStatement(this.props.statement)}>💩&nbsp;</button>
                <div className="line"></div>
                <button>&nbsp;🔥</button>
            </div>}
        >
            <span onClick={() => this.setState({open: !this.state.open})}> {this.props.text}</span>
        </Popover>
    }
}
