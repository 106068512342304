import React from "react";

export default class UserChatMessage extends React.Component {
    render() {
        return <>
            <div className="chat-message-profile-avatar">
                <div>
                    <img src={'/client-chat-avatar.png'} alt=""/>
                </div>
            </div>
            <div className="chat-message-profile-name">
                <div>
                    <h3>You:</h3>
                </div>
            </div>
            <div className="chat-message-content">
                <div><p>{this.props.value.message}</p></div>
            </div>
        </>;
    }
}
