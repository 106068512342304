import React from "react";
import "./Choice.scss";

export default class Choice extends React.Component {

    choose = (i) => {
        this.props.choose(i);
    }

    render() {
        return <>
            <div className="choices">
                {this.props.choice.map((i) => <div className="choice" onClick={(e) => this.choose(i)}>
                    <span className="choice-label">
                        {i}
                    </span>
                    <span className="choice-description">

                    </span>
                </div>)}
            </div>
        </>
    }
}
