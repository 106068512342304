import React, {Component} from 'react';
import {signOut} from "firebase/auth";
import {auth} from './firebase';
import {ChatComponent} from "./ChatComponent";
import "./Home.scss";
import Cookies from 'js-cookie';
import {addContext} from "./AppContext";
import {User} from "@firebase/auth/dist/auth-public";
import {DeferredChatLoader, RealChat, VirtualNewChat, VirtualOldChat} from "./Chat.ts";

class Home extends Component<{ user: User }, { chat: DeferredChatLoader }> {
    private readonly existingChatId: string;

    constructor(props) {
        super(props);
        this.existingChatId = Cookies.get('chatId') as any as string;

        this.state = {
            chat: this.existingChatId ? new DeferredChatLoader(new VirtualOldChat()) : new DeferredChatLoader(new VirtualNewChat()),
        };
    }

    async componentDidMount() {
        if (this.existingChatId) {
            this.state.chat.loaded(new RealChat(this.existingChatId));
            addContext('chatId', this.existingChatId);
        } else {
            try {
                const chatId = await this.createChat();
                Cookies.set('chatId', chatId, {expires: 7}); // Save chatId in cookies, expires in 7 days
                this.state.chat.loaded(new RealChat(chatId));
                addContext('chatId', chatId);
            } catch (error) {
                console.error('Error creating chat session:', error);
            }
        }
    }

    async createChat() {
        const res = await fetch(process.env.REACT_APP_BACKEND + '/create', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${await this.props.user.getIdToken()}`
            },
        });
        if (!res.ok) {
            throw new Error(`${res.status} ${res.statusText}`);
        }
        return (await res.json()).chatId;
    }

    handleLogout = () => {
        signOut(auth).then(() => {
            console.log("Signed out successfully");
        }).catch(() => {
            // Handle errors here
        });
    };

    render() {

        return (
            <div className="home">
                <div className="home-header">
                    <div>
                        <div className="home-header-logo">
                            <img src="/logo-inapp-wide.png" alt=""/>
                        </div>
                        <div className="home-header-coach-avatar">
                            <img src="/ketocoach-avatar.png" alt=""/>
                        </div>
                        <div className="home-header-my-account">
                            <div>
                                <img src="/my-account.png" alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="home-chat">
                    <ChatComponent chat={this.state.chat}/>
                </div>
            </div>
        );
    }
}

export default Home;
