import Cookies from "js-cookie";

// noinspection JSCheckFunctionSignatures
let bacoins = Number(Cookies.get('bacoins') ?? 0);
const callbacks: ((b: number) => void)[] = [];

export function add() {
    bacoins += 1;
    Cookies.set('bacoins', bacoins.toString());
    callbacks.map(c => c(bacoins));
}

export function reset() {
    bacoins = 0;
    Cookies.set('bacoins', '0');
    callbacks.map(c => c(bacoins));
}

export function listen(callback: (b: number) => void) {
    callbacks.push(callback);
    return bacoins;
}

export function get() {
    return bacoins;
}
