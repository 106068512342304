import React from "react";
import "./Debug.scss";
import {context} from "./AppContext";
import {signOut} from "./Auth";

export default class Debug extends React.Component<any, any> {

    constructor(props: any, context: any) {
        super(props, context);
        this.state = {open: false};
    }

    clear = async () => {
        this.deleteAllCookies();
        await signOut();
        window.location.reload();
    };
    toggleContext = () => {
        this.setState({open: !this.state.open});
    };

    deleteAllCookies() {
        const cookies = document.cookie.split(";");

        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i];
            const eqPos = cookie.indexOf("=");
            const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }
    }

    render() {
        return (
            <div className={"debug"}>
                <button className="debug-open" onClick={this.toggleContext}>Open</button>
                <div className={`debug-context ${this.state.open ? 'open' : ''}`} onClick={this.toggleContext}>
                    <div>
                        <code>{JSON.stringify(context)}</code>
                        <br/>
                        <button className={'debug-clear'} onClick={this.clear}>Clear!</button>
                    </div>
                </div>
            </div>
        );
    }
}
