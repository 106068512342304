import {createRoot} from 'react-dom/client';
import './index.css';
import './Fonts.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import App from './App';
import 'simplebar-react/dist/simplebar.min.css';
import {Clarity} from "./Clarity";

function throwExpression(errorMessage: string): never {
    throw new Error(errorMessage);
}

Clarity.set("env", process.env.NODE_ENV as string);

const root = createRoot(document.getElementById('root') ?? throwExpression('Cannot find root'));
root.render(
    <App/>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
